<template>
  <div class="courseBox">
    <div class="courseImage">
      <img style="cursor: pointer"
           :src="coursePic" @click="goCourseMain">
    </div>
    <div class="courseInfoBox">
      <span @click="goCourseMain" style="cursor: pointer">{{ name }}</span>
      <el-progress :percentage="percentage"></el-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseBox',
  data: function () {
    return {}
  },
  props: ['name', 'percentage', 'coursePic', 'termId'],
  methods: {
    goCourseMain () {
      this.$router.push({
        name: 'CourseMain',
        params: {
          termId: this.termId
        }
      })
    }
  }

}
</script>

<style scoped>
.courseBox {
  position: relative;
  height: 200px;
  box-sizing: border-box;
  margin: 20px;
  transition: .3s ease-in-out;
}

.courseBox:hover {
  transform: translate(0, -5px);
}

.courseImage {
  width: 100%;
  text-align: center;
}

.courseImage img {
  width: 100%;
  border-radius: 10px;
  height: 200px;
}

.courseInfoBox {
  width: 100%;
  border-radius: 0 0 10px 10px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px) brightness(110%);
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  transition-duration: .5s;
}
</style>
