<template>
  <div id="iHeader" class="" style="background-color: #ffffff;">
    <div class="container">
      <el-row>
        <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4" style="border: 1px solid transparent">
          <div class="logo_img" @click="goHome()">
            <img src="https://cos.mooc.huangzhaokun.top/moocImg/logo.png" alt="" v-if="!isHome"
                 style="cursor: pointer">
          </div>
          <div style="width: 1px;color: white ;position: absolute">huangzhaokun</div>
        </el-col>
        <el-col :xs="16" :sm="16" :md="16" :lg="20" :xl="20" style="text-align: right">
          <el-menu mode="horizontal" default-active="1"
                   class="headerMenu" style="justify-content: right;display: inline-block;max-height: 60px">
            <slot :navitems="navItems">
              <el-menu-item @click="backurl(item.src)" v-for="item in navItems" :key="item.src" :index="item.index"
                            class="hidden-md-and-down">
                {{ item.tab }}
              </el-menu-item>
            </slot>
            <slot :navitems="navItems">
              <el-submenu index="1" v-if="navItems" class="hidden-lg-and-up">
                <template slot="title">
                  <b-icon icon="list" font-scale="2"></b-icon>
                </template>
                <el-menu-item v-for="item in navItems" :key="item.src" :index="item.index">
                  <el-link @click="backurl(item.src)">{{ item.tab }}</el-link>
                </el-menu-item>
              </el-submenu>
            </slot>
            <!--            <el-menu-item>-->
            <!--              <el-input-->
            <!--                class="hidden-sm-and-down"-->
            <!--                placeholder="请输入内容"-->
            <!--                prefix-icon="el-icon-search"-->
            <!--                v-model="searchContent">-->
            <!--              </el-input>-->
            <!--            </el-menu-item>-->
            <el-menu-item>
              <div v-if="!(user.username==='')">
                <el-avatar @click="goMypage" :src="user.avatarUrl" style="margin-right: 5px"></el-avatar>
                <el-link @click="goMypage"> {{ user.username }}</el-link>
                <el-link style="margin-left: 20px" @click="logout">Logout</el-link>
              </div>
              <div v-else>
                <el-link @click="goLogin">登录</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link @click="goRegister">注册</el-link>
              </div>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import 'element-ui/lib/theme-chalk/display.css'

export default {
  name: 'Header',
  data () {
    return {
      user: {
        username: '',
        avatarUrl: ''
      },
      searchContent: ''
    }
  },
  props: {
    isHome: Boolean,
    navItems: [
      {
        index: String,
        tab: String,
        src: String,
        default: Boolean
      }
    ]
  },
  methods: {
    goMypage () {
      this.$router.push({ path: '/mypage' })
    },
    goLogin () {
      this.$router.push({
        name: 'Login',
        params: {
          fromPath: '/'
        }
      })
    },
    goRegister () {
      this.$router.push({ path: '/register' })
    },
    backurl (value) {
      this.$emit('sendurl', value)
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    logout () {
      this.$confirm('是否退出登录?')
        .then(_ => {
          axios({
            method: 'GET',
            url: 'https://mooc.kirinnorine.top/student/logout'
          }).then(res => {
            if (res.status) {
              this.$cookies.remove('studentInfo')
              this.$message.success('登出成功')
              this.user.username = ''
              this.$router.push({ path: '/' })
            }
          })
        })
        .catch(_ => {
        })
    }
  },
  created () {
    axios({
      methods: 'GET',
      url: 'https://mooc.kirinnorine.top/student/checkLogin'
    }).then(response => {
      if (response.data.status) {
        this.user.username = response.data.data.name
        const email = response.data.data.email
        this.user.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + 565843270 + '&s=100'
        if (email !== null && email.substring(email.length - 7) === '@qq.com') {
          const qqId = email.substring(0, email.indexOf('@'))
          this.user.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
        }
      }
    })
    if (this.navItems !== null) {
      this.navItems.forEach(i => {
        if (i.default === true) {
          this.$emit('sendurl', i.src)
        }
      })
    }
  }
}
</script>

<style scoped>

.logo_img img {
  width: 150px;
  margin: 5px 20px;
}

.headerMenu {
  border: 0 !important;
}

</style>
