<template>
  <div class="studentPage bg-light">
    <Header></Header>
    <div class="container" style="margin-top: 20px">
      <el-row :gutter="20">
        <el-col :md="8" :sm="24" class="animated fadeInLeft">
          <el-card>
            <div>
              <img :src="avatarUrl" alt="" class="avatarImage">
              <div style="float: left;margin-left: 20px">
                <span class="username">{{ username }}</span><br>
                <el-button style="margin-left: 20px" size="small" type="primary" @click="goHome">
                  首页
                </el-button>
                <el-button size="small" @click="goMyBlogs">我的博客</el-button>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 20px">
            <div slot="header" class="clearfix">
              <span>详细信息</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="dialogFormVisible = true">编辑信息
              </el-button>
            </div>
            <p v-show="loginInfo.tel!==null"><i class="el-icon-phone"></i>Tel: {{ loginInfo.tel }}</p>
            <p v-show="loginInfo.email!==null"><i class="el-icon-message"></i>E-mail: {{ loginInfo.email }}</p>
            <p v-show="loginInfo.school!=='unknown'"><i class="el-icon-s-home"></i>School: {{ loginInfo.school }}</p>
            <p v-show="loginInfo.gender!=='unknown'"><i class="el-icon-s-custom"></i>Gender:
              <i v-if="loginInfo.gender==='male'" class="el-icon-male">男</i>
              <i v-else-if="loginInfo.gender==='female'" class="el-icon-female">女</i>
            </p>
            <p v-show="loginInfo.motto!==null"><i class="el-icon-s-opportunity"></i>Motto: {{ loginInfo.motto }}</p>
          </el-card>
          <el-card style="margin-top: 20px">
            <el-skeleton :rows="6" animated/>
          </el-card>
        </el-col>
        <el-col :md="16" :sm="24" class="animated fadeInRight">
          <el-card>
            <div slot="header" class="clearfix">
              <span><i class="el-icon-loading" style="margin-right: 5px"></i>学习中课程</span>
            </div>
            <div class="menuNav">
              <div class="courseSection animated fadeInUp" v-loading="loading1">
                <el-row :gutter="20">
                  <el-col :md="12" :xl="12" :sm="24" v-for="item in detailedSelections" :key="item.course.id">
                    <CourseBox :name="item.course.name" :course-pic="item.course.courseInfo.imageUrl"
                               :percentage="item.completeness" :term-id="item.term.id"></CourseBox>
                  </el-col>
                  <el-empty v-show="detailedSelections.length===0" description="您还没有添加课程!"></el-empty>
                </el-row>
                <div style="text-align: center;">
                  <el-pagination
                    :hide-on-single-page="false"
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    @current-change="showAllCourse"
                    :total="total"
                    layout="prev, pager, next">
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 20px">
            <div slot="header" class="clearfix">
              <span><i class="el-icon-success" style="color: #67C23A;margin-right: 5px"></i>已完成课程</span>
            </div>
            <div class="courseSection animated fadeInUp">
              <el-row :gutter="20">
                <el-col :md="12" :xl="12" :sm="24">
                </el-col>
                <el-empty v-show="true" description="您还没有已完成的课程!"></el-empty>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="编辑信息"
               :visible.sync="dialogFormVisible"
               width="40%"
               style="padding: 5px"
               top="5vh"
               :close-on-click-modal="false"
               v-loading="loading"
    >
      <el-form :model="student" label-position="left" label-width="80px">
        <el-form-item label="姓名：">
          <el-input v-model="student.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-radio-group v-model="student.gender">
            <el-radio label="male"><i class="el-icon-male"/>男</el-radio>
            <el-radio label="female"><i class="el-icon-female"/>女</el-radio>
            <el-radio label="unknown">保密</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学校：">
          <el-input v-model="student.school" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="学号：">
          <el-input v-model="student.sid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input v-model="student.className" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="座右铭：">
          <el-input v-model="student.motto" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeStudentInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header'
import CourseBox from '@/components/CourseBox'
import axios from 'axios'

export default {
  name: 'StudentPage',
  components: {
    CourseBox,
    Header
  },
  data () {
    return {
      currentPage: 1,
      total: 1,
      pageSize: 4,
      loading1: false,
      loading: false,
      username: '',
      avatarUrl: '',
      editInformation: false,
      finishedCourse: [
        {
          name: '三天学java',
          score: 90
        },
        {
          name: 'vue实战教学',
          score: 60
        }
      ],
      toFinishCourse: [
        {
          name: '三天学java',
          percentage: 90
        },
        {
          name: 'vue实战教学',
          percentage: 55
        },
        {
          name: 'ps基础',
          percentage: 60
        }
      ],
      detailedSelections: [],
      activeName: 'second',
      dialogFormVisible: false,
      loginInfo: {},
      student: {
        id: '',
        email: '',
        gender: '',
        motto: '',
        school: '',
        sid: '',
        tel: ''
      },
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '80px'
    }
  },
  created () {
    this.checkLogin()
  },
  methods: {
    goMyBlogs () {
      this.$router.push({
        name: 'MyBlogs',
        params: {
          authorId: this.student.id
        }
      })
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    checkLogin () {
      axios({
        methods: 'GET',
        url: 'https://mooc.kirinnorine.top/student/checkLogin'
      }).then(response => {
        this.showAllCourse()
        this.student = response.data.data
        const json = JSON.stringify(this.student)
        this.loginInfo = JSON.parse(json)
        this.username = response.data.data.name
        const email = response.data.data.email
        this.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + 565843270 + '&s=100'
        if (email !== null && email.substring(email.length - 7) === '@qq.com') {
          const qqId = email.substring(0, email.indexOf('@'))
          this.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
        }
      })
    },
    changeStudentInfo () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/changeInformation',
        params: {
          id: this.student.id,
          name: this.student.name,
          gender: this.student.gender,
          school: this.student.school,
          motto: this.student.motto,
          sid: this.student.sid,
          className: this.student.className
        }
      }).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.checkLogin()
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showAllCourse () {
      this.loading1 = true
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/selection/showMyDetailedSelections',
        params: {
          currentPage: this.currentPage,
          pageSize: 4
        }
      }).then(res => {
        if (res.status) {
          this.detailedSelections = res.data.data.content
          this.total = res.data.data.totalCount
          this.loading1 = false
        }
      })
    }
  },
  mounted () {
  },
  destroyed () {
  }
}
</script>

<style scoped>
.studentPage {
  padding: 0;
  margin: 0;
}

.avatarImage {
  float: left;
  border-radius: 5px;
  height: 80px;
  width: 80px;
  vertical-align: middle;
  margin-bottom: 20px;
}

.username {
  margin-left: 20px;
  font-size: 20px;
}

.avatarSection img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.menuNav {
  padding: 20px 0 0 0;
}

.courseSection {
  padding: 10px 0;
  margin-bottom: 10px;
}
</style>
